import React from "react";

// Features
import FeatureTargetImg from "assets/media/images/savings/features-target.jpg";
import FeatureFixedImg from "assets/media/images/savings/features-fixed.jpg";
import FeatureFlexibleImg from "assets/media/images/savings/features-flexible.jpg";

// Testimonials
import MiraImg from "assets/media/images/savings/testimonial-mira.jpg";

//Qualities
import PayGif from "assets/media/gifs/savings/pay.gif";
import RunGif from "assets/media/gifs/savings/run.gif";
import GrowGif from "assets/media/gifs/savings/grow.gif";

//Target savings
import RainyImg from "assets/media/images/savings/savings-type-rainy.png";
import StoreImg from "assets/media/images/savings/savings-type-store.png";
import WebsiteImg from "assets/media/images/savings/savings-type-website.png";

export const TARGET_SAVINGS_DATA = [
  {
    text: "Plan for a rainy day",
    img: RainyImg,
    className: "rainy",
  },
  {
    text: "Save for a new store",
    img: StoreImg,
    className: "store",
  },
  {
    text: "Save for your website",
    img: WebsiteImg,
    className: "website",
  },
];

export const FEATURES = [
  {
    title: "Target savings for goal driven  savings",
    img: FeatureTargetImg,
    type: {
      name: "New machine",
      savings_type: "Target",
      rate: "8",
    },
    desc: "Earn daily interest as you save towards your business goals. Whether you’re saving for a new branch or new office equipment, there’s a right type of savings for you.",
    contents: {
      label: "ABOUT TARGET SAVINGS",
      features: [
        "Earn an Interest of up to 8% per annum",
        "Minimum savings period of 3 months",
        "One free withdrawal every month",
        "Automate your savings or save anytime",
      ],
    },
  },
  {
    title: "Fixed deposits for your long term business goals",
    img: FeatureFixedImg,
    type: {
      name: "New branch",
      savings_type: "Fixed",
      rate: "18",
    },
    desc: "Earn daily interest as you save towards your business goals. Whether you’re saving for a new branch or new office equipment, there’s a right type of savings for you.",
    contents: {
      label: "ABOUT FIXED DEPOSITS",
      features: [
        "Earn an Interest of up to 18% per annum",
        "Choose from 30 to 180 days deposit period",
        "Funds cannot be withdrawn during this period",
      ],
    },
  },
  {
    title: "Flexible savings for you to save as you go",
    img: FeatureFlexibleImg,
    type: {
      name: "Flexible savings",
      savings_type: "Flexible",
      rate: "5.5",
    },
    desc: "Earn daily interest as you save towards your business goals. Whether you’re saving for a new branch or new office equipment, there’s a right type of savings for you.",
    contents: {
      label: "ABOUT FLEXIBLE SAVINGS",
      features: [
        "Earn an Interest of up to 8% per annum",
        "Minimum savings period of 3 months",
        "One free withdrawal every month",
        "Automate your savings or save anytime",
      ],
    },
  },
];

export const TESTIMONIALS = [
  {
    image: MiraImg,
    author: "Ted Oladele",
    company: "Mira",
    role: "Co-founder",
    quote: `The process of setting up payroll was a breeze. After a single, streamlined setup, it has been operating flawlessly on its own - we've had no reason to give it a second look.`,
  },
  {
    image: MiraImg,
    author: "Ted Oladele",
    company: "Mira",
    role: "Co-founder",
    quote: `The process of setting up payroll was a breeze. After a single, streamlined setup, it has been operating flawlessly on its own - we've had no reason to give it a second look.`,
  },
  {
    image: MiraImg,
    author: "Ted Oladele",
    company: "Mira",
    role: "Co-founder",
    quote: `The process of setting up payroll was a breeze. After a single, streamlined setup, it has been operating flawlessly on its own - we've had no reason to give it a second look.`,
  },
];

export const QUALITIES = [
  {
    gif: GrowGif,
    text: "Grow your business with Brass collection products from Terminals, QR and cash collection services.",
  },
  {
    gif: RunGif,
    text: "Run your business on a financial account trusted by 30,000 ambitious businesses, with free sub-accounts and team banking.",
  },
  {
    gif: RunGif,
    text: "Get paid with an invoicing product that gets out of the way with support for instalments, taxes and discounts.",
  },
  {
    gif: PayGif,
    text: "Never run out of cash for your business. Whenever you need it, access the financing you need to support your business.",
  },
  {
    gif: PayGif,
    text: "Pay your teams with our simple payroll service that provides relevant tooling & insight for your business.",
  },
  {
    gif: GrowGif,
    text: "Expense management done right so you can take control of every business expense while you give freedom to your teams.",
  },
];

export const frontmatter = {
  name: "savings",
  faqContent: [
    {
      question: "Do I need a Brass account to access Brass Savings?",
      answer:
        "Yes. You need a Brass account to access the Brass Savings product.",
    },
    {
      question: "Can I use Brass Savings as an enterprise account holder?",
      answer:
        "Yes. You can use Brass savings if you are registered as an enterprise account holder as business registration documents are not a requirement for the Brass savings account.",
    },
    {
      question: "Can I access my fixed deposit before maturity date?",
      answer: "No. You can not liquidate your fixed deposit savings.",
    },
    {
      question: "What documents do I need to sign up on Brass Savings?",
      answer:
        "You don't need any additional document to save with Brass savings",
    },
  ],
};
